import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
// import PortalVue from 'portal-vue'

import { BootstrapVue, BootstrapVueIcons, BVToastPlugin, BVModalPlugin } from 'bootstrap-vue'

// directives
import autoFocus from '@/directives/auto-focus';
import entrapFocus from '@/directives/entrap-focus';

import cloneDeep from 'lodash.clonedeep';

// components
import { VueEditor } from "vue2-editor";
import vSelect from "vue-select";

import tritonLogo from '@/components/globals/triton-logo';
import cascadeJetSvg from './components/globals/cascade-jet-svg';
import { globalMixin } from './mixins/global-mixin';
import activateModal from '@/components/globals/activate-modal';
import deactivateModal from '@/components/globals/deactivate-modal';
import deactivationReasonsModal from '@/components/globals/deactivation-reasons-modal';

import createNewUserModal from '@/components/globals/create-new-user-modal';
import roleDefinitionsList from '@/components/globals/role-definitions-list';
import accountForm from '@/components/globals/account-form';
import subscriptionTypesTable from '@/components/globals/subscription-types-table';
import tokenForm from '@/components/globals/token-form';
import endpointForm from '@/components/globals/endpoint-form';
import endpointUpdateForm from '@/components/globals/endpoint-update-form';
import loadBalancersTable from '@/components/globals/load-balancers-table';
import loadBalancerForm from '@/components/globals/load-balancer-form';


// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import '@/styles/theme.scss';
import '@/styles/main.css';

Vue.prototype.$clone = cloneDeep;

Vue.mixin(globalMixin);

function components() {
  Vue.component('vueEditor', VueEditor);
  Vue.component("v-select", vSelect);

  Vue.component('tritonLogo', tritonLogo);
  Vue.component('cascadeJetSvg', cascadeJetSvg);

  Vue.component('activateModal', activateModal);
  Vue.component('deactivateModal', deactivateModal);
  Vue.component('deactivationReasonsModal', deactivationReasonsModal);

  Vue.component('createNewUserModal', createNewUserModal);
  Vue.component('roleDefinitionsList', roleDefinitionsList);
  Vue.component('accountForm', accountForm);
  Vue.component('subscriptionTypesTable', subscriptionTypesTable);
  Vue.component('tokenForm', tokenForm);
  Vue.component('endpointForm', endpointForm);
  Vue.component('endpointUpdateForm', endpointUpdateForm);

  Vue.component('loadBalancersTable', loadBalancersTable);
  Vue.component('loadBalancerForm', loadBalancerForm);
}


function directives() {
  Vue.directive('autoFocus', autoFocus);
  Vue.directive('entrapFocus', entrapFocus);
}


function plugins() {
  Vue.use(VueRouter);
  Vue.use(VueMeta);
  Vue.use(BootstrapVue);
  Vue.use(BootstrapVueIcons);
  Vue.use(BVToastPlugin);
  Vue.use(BVModalPlugin);
}


const globals = {
  initialize() {
    components();
    directives();
    plugins();
  }
};




export default globals;
