<template>
  <b-modal
    centered
    class="p-3"
    id="modal-create-new-subscription"
    header-bg-variant="light"
    header-class="py-3"
    size="lg"
    hide-footer
  >
    <template #modal-title>
      <h4>Create Subscription</h4>
    </template>

    <div class="card bg-light border border-primary mb-3">
      <div class="card-body">
        <div class="card-title">
          <h4>Prices</h4>
        </div>
        <ul>
          <li class="card-text my-1">
            <strong class="mr-2">Developer:</strong>Free for devnet
          </li>
          <li class="card-text my-1">
            <strong class="mr-4">Shared:</strong>
            $500/month for light use
          </li>
          <li class="card-text my-1">
            <strong class="mr-2">Dedicated:</strong>
            $2,000/month/server for heavy use
          </li>
        </ul>
      </div>
    </div>

    <b-form v-on:submit.prevent="createSubscription">
      <div class="row">
        <div class="col">
          <b-form-group label="Name">
            <b-form-input v-model="form.name" placeholder="Name" :state="nameState" autofocus />
            <b-form-invalid-feedback :state="nameState">
              {{ nameErrors }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group label="Account" :class="accountErrors ? 'account-error' : ''">
            <v-select
              v-model="form.account"
              :options="accountsDropdownOptions"
              :disabled="accounts.length === 1"
              :clearable="false"
            />
            <b-form-invalid-feedback :state="accountState">
              {{ accountErrors }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group label="Subscription type" :class="subscriptionTypeErrors ? 'account-error' : ''">
            <v-select
              v-model="form.subscriptionType"
              :options="formattedSubscriptionTypes"
              label="label"
              :reduce="option => option.original"
              placeholder="Select a subscription type"
              :clearable="false"
              filterable
              :filter="filterOptions"
            >
              <template #option="option">
                <div>
                  <div>
                    <strong>{{ option.original.name }}</strong>
                    <span v-if="option.original.display_name" class="text-muted ml-1">
        - {{ option.original.display_name }}
      </span>
                  </div>
                  <div v-if="option.original.pool_name" class="text-muted small">
                    Pool: {{ option.original.pool_name }}
                  </div>
                </div>
              </template>
              <template #selected-option="option">
                <div>
                  <strong>{{ option.original.name }}</strong>
                  <span v-if="option.original.display_name" class="text-muted ml-1">
                    - {{ option.original.display_name }}
                  </span>
                </div>
              </template>
            </v-select>
            <b-form-invalid-feedback :state="subscriptionTypeState">
              {{ subscriptionTypeErrors }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group label="Starts at (In UTC)">
            <b-form-datepicker v-model="form.startsAt" />
          </b-form-group>
        </div>

        <div class="col">
          <b-form-group label="Ends at (In UTC)">
            <b-form-datepicker v-model="form.endsAt" />
          </b-form-group>
        </div>
      </div>

      <b-button type="submit" class="btn-primary">
        Save
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import http from '@/services/http';
import { mapGetters, mapState } from 'vuex';
import "vue-select/dist/vue-select.css";

export default {
  name: 'CreateNewSubscriptionModal',

  props: {
    accounts: {
      type: [],
      required: false
    }
  },

  data() {
    return {
      form: {
        name: null,
        account: null,
        subscriptionType: null,
        startsAt: null,
        endsAt: null,
      },
      errors: {},
      filteredOptions: []
    };
  },

  created() {
    this.onCreated()
  },

  computed: {
    ...mapGetters('sessions', [
      'currentUser'
    ]),

    ...mapState({
      subscriptionTypes: state => state.subscriptionTypes.subscriptionTypes
    }),

    accountsDropdownOptions() {
      return this.accounts.map(a => ({ label: a.name, value: a.uuid }));
    },

    subscriptionTypesDropdownOptions() {
      return this.subscriptionTypes.map(st => (st.name));
    },

    nameState() {
      if (this.errors['name']) { return false; }
    },

    nameErrors() {
      const errors = this.errors['name'];
      if (errors) { return errors.join(', ') }
    },

    accountState() {
      if (this.errors['account']) { return false; }
    },

    accountErrors() {
      const errors = this.errors['account'];
      if (errors) { return errors.join(', ') }
    },

    subscriptionTypeState() {
      if (this.errors['subscription_type']) { return false; }
    },

    subscriptionTypeErrors() {
      const errors = this.errors['subscription_type'];
      if (errors) { return errors.join(', ') }
    },

    startsAtState() {
      if (this.errors['starts_at']) { return false; }
    },

    startsAtErrors() {
      const errors = this.errors['starts_at'];
      if (errors) { return errors.join(', ') }
    },

    formattedSubscriptionTypes() {
      if (!this.subscriptionTypes) return [];

      return this.subscriptionTypes.map(type => ({
        label: type.display_name ? `${type.name} - ${type.display_name}` : type.name,
        name: type.name,
        display_name: type.display_name,
        pool_name: type.pool_name,
        original: type
      }));
    },
  },

  methods: {
    async onCreated() {
      if (this.accounts.length === 1) {
        this.form.account = { label: this.accounts[0].name, value: this.accounts[0].uuid };
      }

      if (this.subscriptionTypes.length === 0) {
        await this.$store.dispatch('subscriptionTypes/fetchSubscriptionTypes');
      }
    },

    filterOptions(options, label) {
      if (!label) return options; // Return all options if no search text

      console.log("options:", options);

      const searchText = label.toLowerCase().trim();
      return options.filter(option => {
        const name = option.name?.toLowerCase() || '';
        const displayName = option.display_name?.toLowerCase() || '';
        const poolName = option.pool_name?.toLowerCase() || '';

        console.log('Searching:', {
          searchText,
          name,
          displayName,
          poolName
        });

        return name.includes(searchText) ||
          displayName.includes(searchText) ||
          poolName.includes(searchText);
      });
    },

    async createSubscription() {
      try {
        const response = await http.post('subscriptions', {
          subscription: {
            name: this.form.name,
            account_uuid: this.form?.account?.value,
            subscription_type: this.form.subscriptionType,
            starts_at: this.form.startsAt,
            ends_at: this.form.endsAt
          }
        });

        this.$root.$bvToast.toast(`You successfully created the subscription`, {
          title: 'Subscription created',
          variant: 'default'
        });

        this.$router.push({
          name: 'editSubscription',
          params: { uuid: response.data.subscription.uuid }
        });
      }
      catch (error) {
        console.log('Error:', error)
        this.errors = error.data.errors;
      }
    }
  }
}
</script>

<style lang="scss">
  .vs__dropdown-toggle {
    --vs-border-color: #d8e2ef;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

    .vs__selected {
      padding: 0 0.6rem;
    }
  }

  .vs__dropdown-option--highlight .text-muted {
    color: #FFFFFF !important;
  }

  .account-error .vs__dropdown-toggle {
    --vs-border-color: red;
  }

  .vs--disabled .vs__dropdown-toggle {
    --vs-disabled-bg: #edf2f9
  }

  .form-group .b-form-btn-label-control.form-control[aria-disabled=true] {
    color: #344050;
    background-color: #edf2f9;
  }

  .modal-dialog h4 {
    margin: 0;
  }
</style>
