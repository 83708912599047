import VueRouter from 'vue-router';
import store from '@/store/store.js';

import NotFound from '@/components/pages/NotFound';

import SetUsernameAndPassword from '@/components/pages/users/SetUsernameAndPassword';
import SignIn from '@/components/pages/SignIn';
import ManageUserRpc from '@/components/pages/users/ManageUserRpc';
import ForgotPassword from '@/components/pages/users/ForgotPassword';
import ForgotUsername from '@/components/pages/users/ForgotUsername';
import SetNewPassword from '@/components/pages/users/SetNewPassword';
import ConfirmUser from '@/components/pages/users/ConfirmUser';
import CreateUser from '@/components/pages/users/CreateUser';

import AdminHome from '@/components/pages/admins/Home';
import AdminAccounts from '@/components/pages/admins/Accounts';
import AdminUsers from '@/components/pages/admins/Users';
import AdminSubscriptions from '@/components/pages/admins/Subscriptions';
import AdminReporting from '@/components/pages/admins/reporting/Reporting';
import EditRateTier from '@/components/pages/rate_tiers/EditRateTier';

import EditAccountView from '@/components/pages/accounts/EditAccountView';
import AccountMetrics from '@/components/pages/accounts/AccountMetrics';
import NewOnboardView from '@/components/pages/accounts/NewOnboardView';

import EditSubscriptionView from '@/components/pages/subscriptions/EditSubscriptionView'

import NewSearchView from '@/components/pages/searches/NewSearchView'
import CoinflowWebhookRequests from '@/components/pages/admins/CoinflowWebhookRequests'
import AccountsHelioWebhookEvents from '@/components/pages/admins/AccountsHelioWebhookEvents';

import MarketplaceHome from '@/components/pages/marketplace/Home';
import AdminMarketplaceHome from '@/components/pages/marketplace/AdminHome';
import MarketplaceMetrics from '@/components/pages/marketplace/Metrics';

const router = new VueRouter({
  mode: 'history',

  routes: [
    {
      name: 'root',
      path: '/',
      meta: { requiresCurrentUser: true },
      beforeEnter: (to, from, next) => {
        if (!store.getters['sessions/currentUser']) {
          next('/users/sign-in')
        } else if (store.getters['sessions/currentUser'].is_admin) {
          next('/admins/accounts')
        } else {
          next('/users/me')
        }
      }
    },
    {
      name: 'setUsernameAndPassword',
      path: '/users/confirmations/set-username-and-password',
      component: SetUsernameAndPassword,
      meta: { preventCurrentUser: false }
    },
    {
      name: 'signIn',
      path: '/users/sign-in',
      component: SignIn,
      meta: { preventCurrentUser: true },
      props: true
    },
    {
      // prevent access if signed in
      path: '/users/password/new',
      component: ForgotPassword,
      meta: { preventCurrentUser: true },
    },
    {
      // prevent access if signed in
      path: '/users/username/retrieve',
      component: ForgotUsername,
      meta: { preventCurrentUser: true },
    },
    {
      name: 'setNewPassword',
      path: '/users/password/edit',
      component: SetNewPassword,
    },
    {
      // prevent access if signed in
      path: '/users/confirmation/new',
      component: ConfirmUser,
      meta: { preventCurrentUser: true },
    },
    {
      // prevent access if signed in
      name: 'me',
      path: '/users/new',
      component: CreateUser,
      meta: { preventCurrentUser: true },
    },
    {
      name: 'manageUserRpc',
      path: '/users/:uuid',
      component: ManageUserRpc,
      meta: { requiresCurrentUser: true },
    },
    {
      name: 'admins',
      path: '/admins',
      component: AdminHome,
      meta: {
        requiresCurrentUser: true,
        requiresAdminUser: true,
      }
    },
    {
      name: 'accounts',
      path: '/admins/accounts',
      component: AdminAccounts,
      meta: {
        requiresCurrentUser: true,
        requiresAdminUser: true,
      }
    },
    {
      name: 'users',
      path: '/admins/users',
      component: AdminUsers,
      meta: {
        requiresCurrentUser: true,
        requiresAdminUser: true,
      }
    },
    {
      name: 'subscriptions',
      path: '/admins/subscriptions',
      component: AdminSubscriptions,
      meta: {
        requiresCurrentUser: true,
        requiresAdminUser: true,
      }
    },
    {
      name: 'adminReporting',
      path: '/admins/reporting',
      component: AdminReporting,
      meta: {
        requiresCurrentUser: true,
        requiresAdminUser: true,
      }
    },
    {
      name: 'editRateTier',
      path: '/admins/rate_tiers/:uuid',
      component: EditRateTier,
      meta: {
        requiresCurrentUser: true,
        requiresAdminUser: true,
      }
    },
    {
      path: '/accounts/new-onboard',
      component: NewOnboardView,
      meta: { requiresCurrentUser: true }
    },
    {
      name: 'editAccount',
      path: '/accounts/:uuid',
      component: EditAccountView,
      meta: { requiresCurrentUser: true }
    },
    {
      name: 'accountMetrics',
      path: '/accounts/:uuid/metrics',
      component: AccountMetrics,
      meta: { requiresCurrentUser: true }
    },
    {
      name: 'editSubscription',
      path: '/subscriptions/:uuid',
      component: EditSubscriptionView,
      meta: { requiresCurrentUser: true }
    },
    {
      name: 'marketplaceHome',
      path: '/subscriptions/:uuid/marketplace',
      component: MarketplaceHome,
      meta: { requiresCurrentUser: true, requiresAdminUser: true } // TODO: remove requiresAdminUser
    },
    {
      name: 'newSearchView',
      path: '/search',
      component: NewSearchView,
      meta: { requiresCurrentUser: true, requiresAdminUser: true}
    },
    {
      name: 'coinflowWebhookRequests',
      path: '/coinflow-webhook-requests',
      component: CoinflowWebhookRequests,
      meta: { requiresCurrentUser: true, requiresAdminUser: true }
    },
    {
      name: 'accountsHelioWebhookEvents',
      path: '/helio-webhook-events',
      component: AccountsHelioWebhookEvents,
      meta: { requiresCurrentUser: true, requiresAdminUser: true }
    },
    {
      name: 'adminMarketplace',
      path: '/admins/marketplace',
      component: AdminMarketplaceHome,
      meta: { requiresCurrentUser: true, requiresAdminUser: true }
    },
    {
      name: 'marketplaceMetrics',
      path: '/accounts/:uuid/marketplace/metrics',
      component: MarketplaceMetrics,
      meta: { requiresCurrentUser: true },
      props: true
    },
    {
      name: 'notFound',
      path: "/not-found",
      component: NotFound,
      meta: { requiresCurrentUser: true },
      props: true
    },
  ]
});


router.beforeEach(async (to, from, next) => {
  try {
    // Skip session checks for routes that prevent logged in users
    if (to.matched.some(route => route.meta.preventCurrentUser)) {
      if (store.getters['sessions/currentUser']) {
        next('/');
        return;
      }
      next();
      return;
    }

    // Initialize session if needed for protected routes
    if (to.matched.some(route => route.meta.requiresCurrentUser) && !store.getters['sessions/currentUser']) {
      await store.dispatch('sessions/initializeSession');
    }

    // Handle expired sessions
    if (store.state.sessions.isExpired) {
      store.commit('sessions/CLEAR_SIGN_IN_ERROR');
      next('/users/sign-in');
      return;
    }

    // Handle routes requiring admin access
    if (to.matched.some(route => route.meta.requiresAdminUser)) {
      if (!store.getters['sessions/isCurrentUserAdmin']) {
        next('/');
        return;
      }
    }

    next();
  } catch (error) {
    console.error('Navigation guard error:', error);
    store.commit('sessions/SET_SIGN_IN_ERROR', 'Session initialization failed');
    next('/users/sign-in');
  }
});

export default router;
