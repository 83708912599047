<template>
  <div>
    <div v-if="isLoadingOrders" class="text-center my-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div v-else>
      <b-table
        :items="orders"
        :fields="fields"
        small
        responsive
        :tbody-tr-class="rowClass"
      >
        <template #cell(current)="data">
          <b-badge
            v-if="isCurrentSubscriptionOrder(data.item)"
            :variant="isAccepted(data.item) ? 'success' : 'danger'"
          >
            My order
          </b-badge>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PublicOrderBookTable',

  props: {
    orders: {
      type: Array,
      required: true,
    },
    isLoadingOrders: {
      type: Boolean,
      required: true,
    },
    currentSubscriptionBidderId: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      fields: [
        { key: 'bidder_id', label: 'Bidder ID' },
        { key: 'current', label: '' },
        { key: 'requested_bandwidth', label: 'PPS' },
        { key: 'max_bid', label: 'Max Bid' },
      ],
    };
  },

  computed: {
    ...mapGetters('marketplaceEpochs', ['marketplaceEpochs']),
  },

  methods: {
    rowClass(item) {
      return [
        this.acceptedRejectedClass(item),
        this.isOrderForCurrentSubscriptionClass(item),
      ]
        .filter(Boolean)
        .join(' ');
    },

    isCurrentSubscriptionOrder(item) {
      return item.bidder_id === this.currentSubscriptionBidderId;
    },

    isAccepted(item) {
      return item.allocations_for_epoch.length > 0;
    },

    acceptedRejectedClass(item) {
      if (this.isAccepted(item)) {
        return 'row-accepted';
      } else {
        return 'row-rejected';
      }
    },

    isOrderForCurrentSubscriptionClass(item) {
      return this.isCurrentSubscriptionOrder(item)
        ? 'highlight-current-subscription'
        : '';
    },
  },
};
</script>

<style>
.row-accepted {
  background-color: #e6ffed;
}

.row-rejected {
  background-color: #ffe6e6;
}

.highlight-current-subscription {
  font-weight: bold;
}

.row-accepted.highlight-current-subscription {
  background-color: #b2f2bb; /* Darker green */
}

.row-rejected.highlight-current-subscription {
  background-color: #ffb3b3; /* Darker red */
}
</style>
