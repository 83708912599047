<template>
  <div class="container p-3">
    <div v-if="isLoading" class="loading-overlay mt-3">
      <b-spinner class="align-middle mr-3"></b-spinner>
      <strong>Loading...</strong>
    </div>
    <div v-else>
      <div class="card">
        <h1 class="card-header">
          Rate Tier: {{ rt.name }}
        </h1>

        <div class="card-body">
          <b-tabs card class="rate-tier-tabs">
            <b-tab title="Configurations">
              <b-form v-on:submit.prevent="updateRateTier">
                <b-form-group label="Name" description="Cannot be updated">
                  <b-form-input v-model="rt.name" disabled />
                </b-form-group>
                <b-form-group description="Determines whether or not rate tier is visible to non-admins">
                  <b-form-checkbox v-model="rt.is_restricted" size="lg" switch>
                    <template v-if="rt.is_restricted">
                      Restricted
                    </template>
                    <template v-else>
                      Not Restricted
                    </template>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Definition" description="JSON definition for Load Balancers">
                  <b-form-textarea v-model="rt.definition" rows="20" :state="definitionState" />
                  <b-form-invalid-feedback :state="definitionState">
                    Invalid JSON definition
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-button variant="primary" :disabled="isSubmitting" type="submit">
                  <span v-if="isSubmitting">
                    <b-spinner small></b-spinner>
                    <span class="sr-only">Loading...</span>
                  </span>
                  <span class="emboss" v-else>Save</span>
                </b-button>
              </b-form>
            </b-tab>
            <b-tab title="Endpoints">
              <rate-tier-endpoints-table :rate-tier-uuid="uuid" />
            </b-tab>
            <b-tab title="Tokens">
              <rate-tier-tokens-table :rate-tier-uuid="uuid" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';

import rateTierEndpointsTable from './rate-tier-endpoints-table';
import rateTierTokensTable from './rate-tier-tokens-table';

export default {
  name: 'EditRateTier',

  components: { rateTierEndpointsTable, rateTierTokensTable },

  created() {
    this.onCreated();
  },

  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      definitionState: null,
      rt: {}
    }
  },

  computed: {
    uuid() {
      return this.$route.params.uuid;
    }
  },

  methods: {
    async onCreated() {
      try {
        this.isLoading = true;
        const response = await http.get(`/rate_tiers/${this.uuid}`);
        this.rt = response.data.rate_tier;
      } catch (error) {
        console.error(error);
        this.$bvToast.toast('An unknown error has occurred', {
          variant: 'danger'
        });
      } finally {
        this.isLoading = false;
      }
    },

    async updateRateTier() {
      try {
        this.isSubmitting = true;
        this.definitionState = null;

        if (this.validateJSON()) {
          const acceptedKeys = ['is_restricted', 'definition'];
          await http.put(`/rate_tiers/${this.uuid}`, { rate_tier: filterObjectKeys(this.rt, acceptedKeys) });
          this.$bvToast.toast('Rate Tier updated successfully', {
            title: 'Success',
            variant: 'default'
          });
        } else {
          this.$bvToast.toast('Invalid JSON definition', {
            title: 'Error',
            variant: 'danger'
          });
        }
      } catch (error) {
        console.error(error);
        this.$bvToast.toast('Error updating Rate Tier', {
          title: 'Error',
          variant: 'danger',
        });
      } finally {
        this.isSubmitting = false;
      }

    },

    validateJSON() {
      try {
        JSON.parse(this.rt.definition);
        this.definitionState = null;
        return true;
      } catch (e) {
        this.definitionState = false;
        return false;
      }
    }
  }
}
</script>

<style lang="scss">
.rate-tier-tabs {
  .card-header {
    background-color: #edf2f9;
    border: 1px solid #d8e2ef;

    .nav-link.active {
      background-color: #ffffff;
      border-bottom: 1px solid #ffffff;
    }
  }

  .tab-content .card-body{
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #d8e2ef;
  }
}
</style>
