import http from '@/services/http';

export default {
  namespaced: true,

  state: {
    isLoadingOrders: false,
  },

  getters: {
    isLoadingOrders: state => state.isLoadingOrders,
  },

  mutations: {
    SET_LOADING_ORDERS(state, isLoadingOrders) {
      state.isLoadingOrders = isLoadingOrders
    },
  },

  actions: {
    async fetchSubscriptionsOrders({ commit }, subscriptionsOrdersParams) {
      try {
        commit('SET_LOADING_ORDERS', true);

        const response = await http.get('subscription_marketplace_orders', {
          params: subscriptionsOrdersParams
        });

        return response.data.subscription_marketplace_orders;
      }
      finally {
        commit('SET_LOADING_ORDERS', false);
      }
    },
  },

}
