<template>
  <div>
    <div v-if="isLoadingOrders" class="text-center my-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div v-else>
      <b-table
        :items="orders"
        :fields="fields"
        small
        responsive
        :tbody-tr-class="rowClass"
      >
        <template v-slot:cell(account_name)="data">
          {{ data.item.account_name }}
        </template>

        <template v-slot:cell(cascade_bidder_id)="data">
          <b-link :to="`/subscriptions/${data.item.subscription.uuid}/marketplace`">
            {{ data.item.subscription.cascade_bidder_id }}
          </b-link>
        </template>

        <!-- <template v-slot:cell(actions)="data">
          <b-button
            v-if="data.item.status === 'accepted'"
            variant="link"
            class="p-0"
            @click.stop="openCancelModal(data.item)"
          >
            <b-icon icon="x-circle" variant="danger"></b-icon>
          </b-button>
        </template> -->
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminMarketplaceOrdersTable',

  props: {
    orders: {},
    marketplaceEpochs: {
      type: Array,
    },
    isLoadingOrders: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {

    };
  },

  computed: {
    fields() {
      return [
        { key: 'account_name', label: 'Account' },
        { key: 'cascade_bidder_id', label: 'Bidder ID' },
        { key: 'requested_bandwidth', label: 'PPS' },
        { key: 'max_bid', label: 'Max Bid' },
        { key: 'beginning_marketplace_epoch_number', label: 'Starts' },
        { key: 'ending_marketplace_epoch_number', label: 'Ends' },
        // { key: 'actions', label: '' },
      ];
    },
  },

  methods: {
    rowClass(item) {
      if (item.allocations_for_epoch.length) {
        return 'row-accepted';
      } else {
        return 'row-rejected';
      }
    },
  },
};
</script>

<style>
.row-accepted {
  background-color: #e6ffed; /* Light green */
}

.row-rejected {
  background-color: #ffe6e6; /* Light red */
}
</style>