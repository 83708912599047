<template>
  <div class="layout fill-height min-vh-100">
    <sidebar v-if="currentUser" />
    <div
      class="d-flex main-content"
      :style="{ marginLeft: currentUser ? '250px' : '0' }"
    >
      <top-nav v-if="currentUser" />
      <b-modal
        v-if="showPaymentPopup"
        :visible="paymentModalIsOpen"
        id="payment-required"
        class="p-3"
        title="Submit payment to activate your RPC"
        hide-footer
      >
        <div v-if="showHelioPopup">
          <helio-payment-modal />
        </div>
        <div v-else>
          <coinflow-payment-modal />
        </div>
      </b-modal>

      <info-banner />

      <sign-in-modal />

      <transition
        name="fade"
        mode="out-in"
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
      >
        <router-view class="remaining pb-3" />
      </transition>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapMutations } from 'vuex';
import _ from 'lodash'
import events from '@/services/events';
import topNav from './top-nav';
import bottomNav from './bottom-nav';
import infoBanner from './info-banner';
import sidebar from './sidebar';
import signInModal from './sign-in-modal';
import helioPaymentModal from './helio-payment-modal';
import coinflowPaymentModal from './coinflow-payment-modal';
import { createConsumer } from '@rails/actioncable';

export default {
  name: 'Layout',

  components: { topNav, bottomNav, infoBanner, sidebar, signInModal, helioPaymentModal, coinflowPaymentModal },

  data() {
    return {
      isSignInModalOpen: false,
    };
  },

  metaInfo: {
    titleTemplate(titleChunk) {
      return titleChunk ? `${titleChunk} | Triton One` : 'Triton One';
    },
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters('sessions', [
      'currentUser',
      'paymentModalIsOpen',
      'hasAccountWithHelio',
      'hasAccountWithCoinflow',
      'hasAccountAndSubscription',
      'currentUserHelioAccounts',
      'currentUserCoinflowAccounts',
    ]),

    showPaymentPopup() {
      return this.showHelioPopup || this.showCoinflowPopup;
    },

    showHelioPopup() {
      return (
        this.currentUser &&
        !this.currentUserIsAdmin &&
        this.hasAccountAndSubscription &&
        this.hasAccountWithHelio
      );
    },

    showCoinflowPopup() {
      return this.currentUser &&
             !this.currentUserIsAdmin &&
             this.hasAccountAndSubscription &&
             this.hasAccountWithCoinflow &&
             this.hasInactiveAccountWithCoinflow;
    },

    currentUserIsAdmin() {
      return this.currentUser && this.currentUser.is_admin;
    },

    accountForAdminBanner() {
      return this.$store.state.accounts.currentAccount;
    },

    hasInactiveAccountWithCoinflow() {
      return this.currentUserCoinflowAccounts.some((account) => !account.is_active);
    },
  },

  watch: {
    '$store.state.sessions.signInStatus': 'handleSignInStatusChange',
    $route: 'scrollToTop',
    'currentUser': 'addGoSquaredScript'
  },

  methods: {
    ...mapMutations('sessions', ['HIDE_PAYMENT_MODAL', 'SHOW_PAYMENT_MODAL', 'UPDATE_CURRENT_USER_ACCOUNT', 'UPDATE_ACCOUNTS_SUBSCRIPTIONS']),

    //Main transition animation between pages
    beforeEnter(el, done) {
      el.style.opacity = 0;
      el.style.transform = 'translateY(50px) scale(0.98)';
    },
    enter(el, done) {
      setTimeout(() => {
        el.style.transition =
          'opacity 0.1s cubic-bezier(.18,0,.51,.99), transform 0.1s cubic-bezier(.18,0,.51,.99)';
        el.style.opacity = 1;
        el.style.transform = 'translateY(0) scale(1)';
        done();
      }, 50);
    },
    leave(el, done) {
      el.style.transform = 'translateY(-50px) scale(0.98)';
      el.style.opacity = 0;
      setTimeout(() => done(), 100);
    },

    onCreated() {
      this.$store.dispatch('getSystemFeatures');

      events.$on('unauthorized', this.handleUnauthorized);

      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId === 'payment-required') {
          this.HIDE_PAYMENT_MODAL();
        }
      });
    },

    handleSignInStatusChange(status) {
      if (status && status !== 'success') {
        if (this.$route.name !== 'signIn') {
          this.$router.push('/users/sign-in');
        }
      } else if (status && status === 'success') {
        // sign in successful. Do some other things:
        if (!this.currentUser.is_admin && this.hasAccountAndSubscription) {
          if (this.hasAccountWithHelio || this.hasAccountWithCoinflow) {
            this.createAccountWebSocket();
          }
        }
      }
    },

    handleUnauthorized() {
      this.$store.dispatch('sessions/unauthorizedAccess');

      if (this.$route.name !== 'signIn') {
        this.$bvModal.show('sign-in-modal');
      }
    },

    scrollToTop(newRoute, previousRoute) {
      if (newRoute.path !== previousRoute.path) {
        window.scrollTo(0, 0);
      }
    },

    createAccountWebSocket() {
      const _this = this;
      const consumer = createConsumer();

      this.currentUserHelioAccounts.concat(this.currentUserCoinflowAccounts).forEach((account) => {
        consumer.subscriptions.create(
          { channel: 'AccountsChannel', account_uuid: account.uuid },
          { received: _this.accountEventReceived }
        );
      });
    },

    accountEventReceived(data) {
      events.$emit(`accountEventReceived-${data.account_uuid}`, data);

      // hide the payment modal if is_active is changing to true
      if (data.is_active) {
        this.HIDE_PAYMENT_MODAL();
        this.UPDATE_ACCOUNTS_SUBSCRIPTIONS(data);
      } else {
        this.SHOW_PAYMENT_MODAL();
      }

      this.UPDATE_CURRENT_USER_ACCOUNT(data);
    },

    addGoSquaredScript() {
      const hostname = window.location.hostname;

      if (this.currentUser && hostname === 'customers.triton.one') {
        (function(g,s,q,r,d){
          r=g[r]=g[r]||function(){(r.q=r.q||[]).push(arguments)};
          d=s.createElement(q);d.src='//d1l6p2sc9645hc.cloudfront.net/gosquared.js';
          q=s.getElementsByTagName(q)[0];q.parentNode.insertBefore(d,q)
        }(window,document,'script','_gs'));

        _gs('GSN-808359-B');
        _gs('set', 'anonymizeIP', true);
      }
    },
  },
};
</script>

<style scoped>
.side-content {
  display: flex;
  width: 250px;
}

.main-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  transition: margin-left 0.2s cubic-bezier(0.18, 0, 0.51, 0.99);
}
.fill-height {
  height: 100%;
}

/* background pattern */
.layout {
  background-color: #e1e7ef;
  position: relative;
  z-index: 1;
}

.remaining {
  flex: 1;
}

@media (max-width: 960px) {
  .layout {
    background-color: #EDF2F9;
  }
  .sidebar {
    display: none;
  }
}
@media (max-width: 960px) {
  .main-content {
    margin-left: 0 !important;
  }
}
</style>
