<template>
  <div class="card mb-3">
    <div class="card-header bg-light">
      <h3 class="pt-3">Orders</h3>
    </div>

    <div class="card-body">
      <div class="container-lg">
        <b-table
          :items="ordersWithSummaryInfoShown"
          :fields="fields"
          striped
          responsive
        >
          <template #cell(status)="data">
            <template v-if="orderHasEnded(data.item)">
              <b-badge variant="light">
                Ended
              </b-badge>
            </template>
            <template v-else>
              <span v-for="me in marketplaceEpochsToShowStatusFor(data.item)" :key="me.epoch">
                <template v-if="hasAllocationForMarketplaceEpoch(data.item, me)">
                  <b-badge variant="success" class="mr-1">
                    {{ me.epoch }}
                    <template v-if="me.epoch === currentMarketplaceEpoch.epoch">
                      (Current)
                    </template>
                  </b-badge>
                </template>
                <template v-else>
                  <b-badge variant="danger" class="mr-1">
                    {{ me.epoch }}
                    <template v-if="me.epoch === currentMarketplaceEpoch.epoch">
                      (Current)
                    </template>
                  </b-badge>
                </template>
              </span>
            </template>
          </template>

          <template #cell(actions)="data">
            <div v-if="showActions(data.item)">
              <div class="border-bottom pb-2">
                <b-button @click="$emit('show-end-order-modal', data.item)" variant="outline-secondary" size="sm">
                  End long-running order
                </b-button>
              </div>

              <div class="pt-2">
                <b-button @click="$emit('show-cancel-and-replace-order-modal', data.item)" variant="outline-secondary" size="sm">
                  Cancel & replace to update
                </b-button>
              </div>
            </div>
          </template>

          <template #row-details="data">
            <div class="d-inline-block px-3">
              <template v-if="showEndingMessaging(data.item)">
                <b-alert variant="secondary" show>
                  <b-icon-info-circle variant="secondary" class="mr-2"></b-icon-info-circle>
                  This order is scheduled to end at the end of epoch {{ data.item.ending_marketplace_epoch_number }}.
                </b-alert>
              </template>

              <template v-else-if="showOutbidMessagingForActiveOrder(data.item)">
                <b-alert variant="danger" show>
                  <b-icon-exclamation-circle variant="danger" class="mr-2" />

                  <template v-if="hasAllocationForCurrentEpoch(data.item)">
                    This order is going to be rejected for an upcoming epoch because you were outbid.
                  </template>
                  <template v-else>
                    This order has become rejected because you were outbid.
                  </template>

                  <p class="mt-2">
                    You can:
                  </p>

                  <ul>
                    <li>
                      <b-link @click="$emit('show-cancel-and-replace-order-modal', data.item)">Cancel & replace</b-link> with a higher limit price.
                    </li>

                    <li>
                      Do nothing, and keep your long-running order at its current limit price of <b>{{ data.item.max_bid }} SOL</b> per 10 PPS per epoch. This means that if the floor price decreases to <b>{{ data.item.max_bid }}</b> then your order will automatically be accepted again. You will be notified if the floor price decreases and your order becomes a winning order again.
                    </li>

                    <li>
                      <b-link @click="$emit('show-end-order-modal', data.item)">End your order</b-link> if you no longer wish to participate.
                    </li>
                  </ul>
                </b-alert>
              </template>

              <template v-else-if="hasAllocationForAllFutureEpochs(data.item)">
                <b-alert variant="secondary" show>
                  <b-icon-check-circle variant="secondary" class="mr-2"></b-icon-check-circle>
                  Valid & active
                </b-alert>
              </template>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SubscriptionsOrdersTable',

  props: ['orders'],

  data() {
    return {
      fields: [
        { key: 'beginning_marketplace_epoch_number', label: 'Starts' },
        { key: 'ending_marketplace_epoch_number', label: 'Ends' },
        { key: 'requested_bandwidth', label: 'PPS' },
        { key: 'max_bid', label: 'Max Bid' },
        { key: 'status', label: 'Status' },
        { key: 'actions', label: '' },
      ],
    };
  },

  computed: {
    ...mapGetters('marketplaceEpochs', [
      'currentMarketplaceEpoch',
      'currentAndFutureMarketplaceEpochs',
      'futureEpochsOpenForOrders',
    ]),

    ordersWithSummaryInfoShown() {
      return this.orders.map(order => {
        return {
          ...order,
          _showDetails: true
        };
      });
    },
  },

  methods: {
    showActions(order) {
      if (this.orderHasEnded(order) || order.ending_marketplace_epoch_number == this.currentMarketplaceEpoch.epoch) {
        return false;
      } else {
        return true;
      }
    },

    orderHasEnded(order) {
      return order.ending_marketplace_epoch_number && order.ending_marketplace_epoch_number < this.currentMarketplaceEpoch.epoch;
    },

    hasAllocationForCurrentEpoch(order) {
      return order.allocations.some((allocation) => {
        return allocation.epoch_number === this.currentMarketplaceEpoch.epoch;
      });
    },

    hasAllocationForMarketplaceEpoch(order, marketplaceEpoch) {
      return order.allocations.some((allocation) => {
        return allocation.epoch_number === marketplaceEpoch.epoch;
      });
    },

    marketplaceEpochsToShowStatusFor(order) {
      let marketplaceEpochs = this.$clone(this.currentAndFutureMarketplaceEpochs);

      marketplaceEpochs = marketplaceEpochs.filter((me) => {
        return me.epoch >= order.beginning_marketplace_epoch_number;
      });

      if (order.ending_marketplace_epoch_number) {
        marketplaceEpochs = marketplaceEpochs.filter((me) => {
          return me.epoch <= order.ending_marketplace_epoch_number;
        });
      }

      return marketplaceEpochs;
    },

    showEndingMessaging(order) {
      return order.ending_marketplace_epoch_number && order.ending_marketplace_epoch_number === this.currentMarketplaceEpoch.epoch;
    },

    showOutbidMessagingForActiveOrder(order) {
      return this.futureEpochsOpenForOrders.some(me => {
        const allocationsForMarketplaceEpoch = order.allocations.filter(allocation => {
          return allocation.epoch_number === me.epoch;
        });

        if (this.orderWantsToBeActiveForEpoch(order, me) && allocationsForMarketplaceEpoch.length === 0) {
          return true;
        }
      });
    },

    hasAllocationForAllFutureEpochs(order) {
      return this.futureEpochsOpenForOrders.every(me => {
        return order.allocations.some(allocation => {
          return allocation.epoch_number === me.epoch;
        });
      });
    },

    orderWantsToBeActiveForEpoch(order, marketplaceEpoch) {
      return order.beginning_marketplace_epoch_number <= marketplaceEpoch.epoch &&
             (!order.ending_marketplace_epoch_number || order.ending_marketplace_epoch_number >= marketplaceEpoch.epoch);
    },
  },
};
</script>
