<template>
  <div class="pt-3">
    <div v-if="isLoading" class="text-center">
      <b-spinner class="align-middle mr-3"></b-spinner>
      <strong>Loading...</strong>
    </div>
    <div v-else>
      <b-table
        small
        :items="filteredMarketplaceEpochs"
        :fields="fields"
        class="table-responsive scrollbar"
        id="marketplace-epochs-table"
        @row-clicked="onRowClicked"
        :tbody-tr-class="rowClass"
      >
        <template #cell(epoch_ends_at_approx)="data">
          <span v-if="data.item.epoch_info && data.item.epoch_info.is_current_epoch">
            {{ formatDate(data.item.epoch_info.epoch_ends_at) }}
          </span>
        </template>

        <template #cell(current)="data">
          <b-badge
            v-if="data.item.epoch_info && data.item.epoch_info.is_current_epoch"
            variant="primary"
          >
            Current epoch
          </b-badge>

          <b-badge
            v-else-if="data.item === soonestEpochOpenForOrders"
            variant="primary"
          >
            Current bidding
          </b-badge>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MarketplaceEpochsTable',

  props: { adminView: Boolean, subscriptionsOrders: Array },

  data() {
    return {
      selectedMarketplaceEpoch: null,
      fields: [
        { key: 'epoch', label: 'Epoch' },
        { key: 'current', label: '', sortable: false },
        { key: 'current_available_bandwidth', label: 'Available' },
        { key: 'current_floor', label: 'Floor' },
        { key: 'epoch_ends_at_approx', label: 'Epoch Ends At' },
        { key: 'orders_close_at', label: 'Orders Close At', formatter: this.formatDate },
      ],
    };
  },

  watch: {
    selectedMarketplaceEpoch() {
      if (this.selectedMarketplaceEpoch) {
        this.$emit('epoch-selected', this.selectedMarketplaceEpoch.epoch);
      }
    },
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters('marketplaceEpochs', [
      'marketplaceEpochs',
      'currentMarketplaceEpoch',
      'soonestEpochOpenForOrders',
      'isLoading',
    ]),

    firstEpochNumberToDisplay() {
      const beginningEpochNumbers = this.subscriptionsOrders.map((order) => order.beginning_marketplace_epoch_number);
      const earliestEpoch = beginningEpochNumbers.sort().at(-1);

      const currentEpochNumber = this.currentMarketplaceEpoch.epoch;

      return earliestEpoch < currentEpochNumber ? currentEpochNumber : earliestEpoch;
    },

    filteredMarketplaceEpochs() {
      if (this.adminView) {
        return this.marketplaceEpochs; // show all the BE returns
      } else {
        if (this.subscriptionsOrders.length === 0) {
          // Return the soonest epoch open for orders

          const currentBiddingIndex = this.marketplaceEpochs.findIndex(
            (epoch) => epoch.epoch === this.soonestEpochOpenForOrders.epoch
          );

          return this.marketplaceEpochs.slice(currentBiddingIndex);
        } else {
          const startingIndex = this.marketplaceEpochs.findIndex(
            (epoch) => epoch.epoch === this.firstEpochNumberToDisplay
          );

          return this.marketplaceEpochs.slice(startingIndex);
        }
      }
    },
  },

  methods: {
    async onCreated() {
      if (this.adminView) {
        this.selectedMarketplaceEpoch = this.currentMarketplaceEpoch;
      } else {
        this.selectedMarketplaceEpoch = this.filteredMarketplaceEpochs[0];
      }
    },

    onRowClicked(item, index, event) {
      this.selectedMarketplaceEpoch = item;
    },

    rowClass(item) {
      if (this.selectedMarketplaceEpoch && item.uuid === this.selectedMarketplaceEpoch.uuid) {
        return 'border border-info rounded selected-epoch-row';
      }
      return '';
    },
  },
};
</script>

<style>
#marketplace-epochs-table tbody {
  cursor: pointer;
}

.selected-epoch-row {
  background-color: rgba(0, 123, 255, 0.1); /* Bootstrap primary color with 10% opacity */
}
</style>
