<template>
  <div>
    <div class="card" v-if="isLoading">
      <div class="card-header text-center my-6">
        <b-spinner class="align-middle mr-3"></b-spinner>
        <strong>Loading metric options...</strong>
      </div>
    </div>
    <div v-else class="container-xl">
      <nav aria-label="breadcrumb" class="my-3">
        <ol class="breadcrumb m-0 p-0 font-weight-bold" style="background: none;">
          <li class="breadcrumb-item">
            <router-link
              class="text-primary"
              :to="{ name: 'editAccount', params: { uuid: accountUuid } }"
            >
              Account: {{ subscriptions[0]?.account?.name }}
            </router-link>
          </li>
          <template v-if="subscriptionUuid">
            <li class="breadcrumb-item">
              <router-link
                class="text-primary"
                :to="{ name: 'editSubscription', params: { uuid: subscriptionUuid } }"
              >
                Subscription: {{ subscriptions.find(s => s.uuid === subscriptionUuid)?.name }}
              </router-link>
            </li>
          </template>
          <li class="breadcrumb-item active text-muted" aria-current="page">
            Cascade Marketplace Metrics
          </li>
        </ol>
      </nav>

      <div class="card">
        <div class="card-header text-center sticky-top">
          <div class="row">

            <!-- Datetimepicker -->
            <div class="col">
              <div class="float-left">
                <div class="mb-1">
                  {{ selectedRange }}
                </div>
                <div>
                  <date-range-picker
                    time-picker
                    time-picker24-hour
                    auto-apply
                    opens="right"
                    :locale-data="{ format: 'yyyy/mm/dd HH:MM' }"
                    v-model="dateRange"
                    :ranges="dates"
                    append-to-body
                    @select="handleSelect"
                  />
                </div>
              </div>
            </div>

            <!-- Auto-refresh selection -->
            <div class="col">
              <div class="mb-1">
                Auto Refresh
                <b-button
                  v-if="selectedAutoRefresh === 'none'"
                  class="ml-1"
                  variant="outline-secondary"
                  size="sm"
                  @click="fetchMetrics"
                >
                  <b-icon
                    icon="arrow-clockwise"
                    aria-hidden="true"
                    v-b-popover.hover.top="'Refresh now'"
                  />
                </b-button>
              </div>
              <div>
                <b-form-radio-group
                  v-model="selectedAutoRefresh"
                  :options="autoRefreshOptions"
                  buttons
                  button-variant="outline-secondary"
                  size="sm"
                />
              </div>
            </div>

            <!-- Subscription type selection -->
            <div class="col float-right">
              <div>
                <div class="mb-1">Selected resource</div>
                <div>
                  <b-form-group>
                    <b-dropdown
                      ref="dropdown"
                      id="resource-dropdown-cleaned"
                      :html="selectedResource?.text || ''"
                      right
                      variant="outline-secondary"
                      @show="onDropdownShow"
                    >
                      <b-dropdown-item class="p-2">
                        <div class="d-flex align-items-center">
                          <b-icon icon="search" aria-hidden="true" class="me-2"></b-icon>
                          <input
                            type="text"
                            class="form-control"
                            v-model="searchQuery"
                            ref="searchInput"
                            placeholder="Search..."
                            @input="filterDropdownOptions"
                            @click="preventDropdownClose"
                          />
                          <b-icon
                            v-if="searchQuery"
                            icon="x"
                            class="ms-2 cursor-pointer"
                            @click="clearSearch"
                          ></b-icon>
                        </div>
                      </b-dropdown-item>

                      <!-- Filtered dropdown options -->
                      <template v-for="(group, groupIndex) in filteredDropdownOptions">
                        <b-dropdown-item
                          v-if="group?.label"
                          :key="'group-' + groupIndex"
                          @click="selectResource({ text: group.label, value: { uuid: group.uuid, name: 'subscription' } })"
                          class="dropdown-header-item"
                        >
                          <span class="dropdown-option">
                            {{ group.label }}
                            <span
                              v-if="selectedResource?.value?.uuid === group.uuid && selectedResource?.value?.name === 'subscription'"
                              class="checkmark"
                            >✓</span>
                          </span>
                        </b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>

          <b-alert
            dismissible
            fade
            :show="showAlert"
            @dismissed="showAlert=false"
            variant="danger"
            class="my-3"
          >
            {{ alertMessage ? alertMessage : 'There was an error fetching metrics' }}
          </b-alert>
        </div>

        <template v-if="selectedResource">
          <div class="card-body">
            <echart chart-name="total-received-transactions" :options="chartOptions"/>

            <echart chart-name="landed-vs-not-landed" :options="chartOptions"/>

            <!-- pie charts -->
            <div class="row">
              <div class="col-8">
                <span class="h4 border-bottom">Transaction Optimisation</span>
                <p class=mt-3>In order to get as good as possible transaction landing rate you want
                  to:</p>
                <ul>
                  <li>Submit transactions without requesting preflight</li>
                  <li>Add priority fee and set compute budget</li>
                  <li>Use the lowest possible compute budget</li>
                  <li>Set priority fee to an appropriate value for your transactions</li>
                </ul>
                <p>
                  For up to date docs, please check <a
                  href="https://docs.triton.one/chains/solana/sending-txs">https://docs.triton.one/chains/solana/sending-txs</a>
                </p>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-4">
              <echart chart-name="transaction-optimisation-preflight" :options="chartOptions"
                      height="300px"/>
            </div>

            <div class="col-4">
              <echart chart-name="transaction-optimisation-priority-fees" :options="chartOptions"
                      height="300px"/>
            </div>

            <div class="col-4">
              <echart chart-name="transaction-optimisation-priority-fees-max-retries"
                      :options="chartOptions" height="300px"/>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="v-chart-heading">Priority fees (Pool)</div>
                  <echart chart-name="transaction-optimisation-priority-fees-pool-50"
                          :options="chartOptions" height="300px"/>
                </div>
                <div class="col">
                  <div>&nbsp;</div>
                  <echart chart-name="transaction-optimisation-priority-fees-pool-90"
                          :options="chartOptions" height="300px"/>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="v-chart-heading">Priority fees (Global)</div>
                  <echart chart-name="transaction-optimisation-priority-fees-global-50"
                          :options="chartOptions" height="300px"/>
                </div>
                <div class="col">
                  <div>&nbsp;</div>
                  <echart chart-name="transaction-optimisation-priority-fees-global-90"
                          :options="chartOptions" height="300px"/>
                </div>
              </div>
            </div>
          </div>

          <echart chart-name="priority-fees-for-landed" :options="chartOptions"/>

          <div class="row">
            <div class="col">
              <echart chart-name="transaction-optimisation-priority-fees-submitted"
                      :options="chartOptions"/>
            </div>

            <div class="col">
              <echart chart-name="transaction-optimisation-priority-fees-landed"
                      :options="chartOptions"/>
            </div>
          </div>

          <echart chart-name="compute-budget-for-landed" :options="chartOptions"/>

          <div class="row">
            <div class="col">
              <echart chart-name="transaction-optimisation-compute-budget-submitted"
                      :options="chartOptions"/>
            </div>

            <div class="col">
              <echart chart-name="transaction-optimisation-compute-budget-landed"
                      :options="chartOptions"/>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'

import http from '@/services/http';
import metricsMixin from '@/mixins/metrics';

export default {
  components: {DateRangePicker},

  name: 'MarketplaceMetrics',

  mixins: [metricsMixin],

  props: ['subscriptionUuid'],

  data() {
    return {
      isLoading: false,
      dateRange: {
        startDate: null,
        endDate: null
      },
      showAlert: false,
      alertMessage: null,
      selectedRange: 'Last 3 Hours',
      selectedAutoRefresh: 'none',
      selectedResource: null,
      dropdownOptions: [],
      subscriptionTypes: [],
      subscriptions: [],
      filteredDropdownOptions: [],
      searchQuery: '',
      isClosable: false,
      isDropdownOpen: false
    }
  },

  created() {
    this.onCreated();
  },

  beforeDestroy() {
    this.clearInterval();
  },

  watch: {
    $route(to, from) {
      if (to.params.uuid !== from.params.uuid) {
        this.onCreated();
      }
    },

    selectedAutoRefresh: function (newVal) {
      this.clearInterval(this.autoRefreshInterval);

      if (newVal !== 'none') {
        this.autoRefreshInterval = setInterval(this.fetchMetrics, parseInt(newVal) * 60 * 1000);
      }
    },

    selectedRange: function () {
      this.showAlert = false;
    },

    selectedResource: function () {
      this.showAlert = false;
    }
  },

  computed: {
    chartOptions() {
      const options = {
        accountUuid: this.$route.params.uuid,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        subscriptionUuid: this.subscriptionUuid
      }

      if (this.selectedResource) {
        const subTypeUuid = this.subscriptionTypes.find(st => st.uuid === this.selectedResource?.value?.uuid)?.uuid;
        if (subTypeUuid) {
          options.subscriptionTypeUuid = subTypeUuid;
        }

        const subUuid = this.subscriptions.find(sub => sub.uuid === this.selectedResource?.value?.uuid)?.uuid;
        if (subUuid) {
          options.subscriptionUuid = subUuid;
        }
      }
      return options;
    },

    accountUuid() {
      return this.$route.params.uuid;
    }
  },

  methods: {
    async onCreated() {
      try {
        this.isLoading = true;

        await this.loadSubscriptionTypesAndSubscriptions();

        // If subscriptionUuid prop is provided, set selectedResource accordingly
        if (this.subscriptionUuid) {
          const exists = this.subscriptions.some(sub => sub.uuid === this.subscriptionUuid);
          if (exists) {
            // get the drop down option for the sub uuid
            const dropdownOption = this.dropdownOptions.find(option => option.uuid === this.subscriptionUuid);
            if (dropdownOption) {
              this.selectedResource = {
                text: dropdownOption.label,
                value: {name: dropdownOption.name, uuid: dropdownOption.uuid}
              };
            }
          } else {
            // Optionally, handle the case where subscriptionUuid does not exist
            this.showAlert = true;
            this.alertMessage = 'Provided subscriptionUuid does not exist.';
            // Fallback to first enabled option
            const dropdownOption = this.dropdownOptions[0];
            if (dropdownOption) {
              this.selectedResource = {
                text: dropdownOption.label,
                value: {name: dropdownOption.name, uuid: dropdownOption.uuid}
              };
            }
          }
        } else {
          // If no subscriptionUuid prop, set to first option
          const dropdownOption = this.dropdownOptions[0];
          if (dropdownOption) {
            this.selectedResource = {
              text: dropdownOption.label,
              value: {name: dropdownOption.name, uuid: dropdownOption.uuid}
            };
          }
        }
        this.dateRange.startDate = this.threeHoursAgo();
        this.dateRange.endDate = this.now();

        this.setCalendarDates();

        if (this.subscriptionTypes.length === 0) {
          this.showAlert = true;
          this.alertMessage = 'This account has no subscription types with a pool name';
        }

      } finally {
        this.isLoading = false;
      }
    },

    async loadSubscriptionTypesAndSubscriptions() {
      const response = await http.get(`subscriptions?account_uuid=${this.accountUuid}`);
      this.subscriptions = response.data.subscriptions;
      this.subscriptionTypes = this.subscriptions.map(s => s.subscription_type).filter(st => st.pool_name);
      // Populate dropdown on component mount
      this.generateDropdownOptions();
    },

    handleSelect(json) {
      const delta = json.endDate - json.startDate;
      for (const range in this.dates) {
        const [start, end] = this.dates[range];
        if (end - start === delta) {
          this.selectedRange = range;
          break;
        }

        this.selectedRange = 'Custom';
      }

      this.setCalendarDates();
    },

    // this doesn't actually fetch the metrics but imposes changes on the child components
    // to make them fetch the metrics
    fetchMetrics() {
      this.showAlert = false;
      this.setCalendarDates();
      this.dateRange.startDate = this.dates[this.selectedRange][0];
      this.dateRange.endDate = this.dates[this.selectedRange][1];
    },

    selectResource(option) {
      if (option.value === null) {
        this.selectedResource = null;
      } else {
        this.selectedResource = option;
      }
      this.$refs.dropdown.hide();
    },

    generateDropdownOptions() {
      const options = [];

      this.subscriptions.filter(s => s.cascade_marketplace).forEach(subscription => {
        if (subscription.subscription_type?.is_dedi) {
          // Does `options` already have this Subscription Type in it?
          if (!options.some(option => option.uuid === subscription.subscription_type.uuid)) {
            const name = subscription.subscription_type.name;

            options.push({
              label: `Subscription Type: ${name}`,
              name: name,
              uuid: subscription.subscription_type.uuid
            });
          }
        } else {
          options.push({
            label: `Subscription: ${subscription.name}`,
            name: subscription.name,
            uuid: subscription.uuid
          });
        }
      })

      this.dropdownOptions = options;
      this.filteredDropdownOptions = this.dropdownOptions;

      if (this.dropdownOptions.length > 0) {
        this.selectedResource = {
          text: this.dropdownOptions[0].label,
          value: { name: this.dropdownOptions[0].name, uuid: this.dropdownOptions[0].uuid }
        }
      } else {
        this.selectedResource = null;
      }
    },

    filterDropdownOptions() {
      const query = this.searchQuery.toLowerCase();

      if (!query) {
        this.filteredDropdownOptions = this.dropdownOptions;
        return;
      }

      this.filteredDropdownOptions = this.dropdownOptions.map(group => {
        if (!group || !group.options) {
          return {...group, options: []};
        }

        const filteredOptions = group.options.filter(option =>
          option.text.toLowerCase().includes(query)
        );

        return {
          ...group,
          options: filteredOptions,
          show: filteredOptions.length > 0
        };
      }).filter(group => group.options && group.options.length > 0);
    },

    preventDropdownClose(event) {
      event.stopPropagation();
    },

    clearSearch(event) {
      this.preventDropdownClose(event);
      this.searchQuery = '';
      this.filteredDropdownOptions = this.dropdownOptions;
    },

    resetClosable() {
      this.isClosable = true; // clear closable state when dropdown is reopened
    },

    onDropdownShow() {
      this.focusSearchInput();
      this.setDropdownWidth();
    },

    focusSearchInput() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.searchInput) {
            this.$refs.searchInput.focus();
          }
        });
      });
    },

    setDropdownWidth() {
      this.$nextTick(() => {
        setTimeout(() => {
          const dropdownMenu = this.$refs.dropdown.$el.querySelector('.dropdown-menu');
          if (dropdownMenu) {
            const initialWidth = dropdownMenu.offsetWidth;
            dropdownMenu.style.minWidth = `${initialWidth}px`;
          }
        });
      });
    }
  }
};
</script>

<style scoped>
.v-chart-heading {
  font-weight: bold !important;
  font: 17px sans-serif;
  color: #464646;
}

.checkmark {
  color: green;
  font-weight: bold;
  margin-left: 10px;
}

.dropdown-menu {
  width: auto !important;
}

.dropdown.b-dropdown {
  width: 100%;
  text-align: left;
}

.dropdown-option {
  font-size: 1rem;
}

.dropdown-option.indented {
  margin-left: 8px;
}

/** we need v-deep here to force the css compile for class rendered in component */
.dropdown-header-item::v-deep .dropdown-item {
  font-size: 1rem !important;
  padding: 0.25rem 1rem !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  letter-spacing: 0.04em !important;
}

/* ::v-deep to target the button within the dropdown */
::v-deep #resource-dropdown-cleaned .dropdown-toggle {
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

/* Add margin between the icon and text */
.me-2 {
  margin-right: 8px;
}

.ms-2 {
  margin-left: 8px;
}

.form-control {
  width: 100%;
  padding: 4px 8px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
